import { useEffect, useState } from "react"
import { Card as AntdCard, Checkbox, Divider, Dropdown } from "antd"
import { DivFlex, Button } from "commons/components"
import { theme } from "commons/utils/theme"
import styled from "styled-components"

interface PropOption {
  label: string
  value: string | number
}

interface Props {
  values?: string[]
  options: PropOption[]
  buttonText: string
  disabled?: boolean

  handleCancel?: () => void
  handleResult?: (selectedValues: string[]) => void
}

const SButton = styled(Button)`
  & .anticon {
    font-size: 10px !important;
    vertical-align: baseline;
  }
`
const CheckboxContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`

const OptionContainer = styled.div`
  max-height: 200px;
  overflow-y: scroll;
`

const DropdownCheckbox: React.FC<Props> = ({
  values,
  options,
  buttonText,
  disabled,
  handleCancel, handleResult
}) => {
  const [, setShowDropdown] = useState(false)
  const toggleShow = () => setShowDropdown(v => !v)
  const [innerValues, setInnerValues] = useState(values)

  useEffect(() => {
    setInnerValues(values)
  }, [values])

  const onCancel = () => {
    setInnerValues(values)
    if (handleCancel) {
      handleCancel()
    }
    toggleShow()
  }
  const onShowResults = () => {
    if (handleResult) {
      handleResult(innerValues || [])
    }
    toggleShow()
  }

  const content = (
    <AntdCard>
      <OptionContainer style={{ padding: 8 }}>
        <Checkbox.Group
          value={innerValues}
          onChange={
            checkedValues => setInnerValues(checkedValues as string[])
          }
          style={{ width: '100%' }}>
          {options.map((option) => {
            return (
              <CheckboxContainer key={option.value} onClick={e => e.stopPropagation()}>
                <Checkbox value={option.value}>{option.label}</Checkbox>
              </CheckboxContainer>
            )
          })}
        </Checkbox.Group>
      </OptionContainer>
      <Divider style={{ marginBottom: 8, marginTop: 0 }} />
      <DivFlex style={{ padding: "0 8px" }}>
        <Button type="link" style={{ color: theme.color.text }}
          onClick={onCancel}>Cancel</Button>
        <Button type="primary" style={{ marginLeft: 8 }} onClick={onShowResults}>Show Results</Button>
      </DivFlex>
    </AntdCard>
  )

  return (
    <Dropdown
      destroyPopupOnHide
      trigger={['click']}
      overlay={content}
      // visible={showDropdown}
      disabled={disabled}
    >
      <SButton type={innerValues?.length ? 'primary':'default'}>{buttonText}</SButton>
    </Dropdown>
  )
}

export default DropdownCheckbox
