import { UploadOutlined } from "@ant-design/icons";
import { Typography, Upload, UploadProps, Spin } from "antd";
import { RcFile } from "antd/lib/upload";
import styled from "styled-components";

const Container = styled.div`
  border: 2px dashed ${(props) => props.theme.color.greyLight2};
  min-height: 135px;
  background-color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 24px;
`;

const StyledUpload = styled(Upload)`
  & .ant-upload-list {
    display: inline;
    &::before,
    &::after {
      display: inherit;
    }
  }
`;

interface Props {
  loading?: boolean;
  multiple?: boolean;
  name?: string;
  accept?: string;
  supportedFiles?: string[];
  uploadProps?: UploadProps;
  handleUpload: (file: RcFile) => void;
}

const FileUpload: React.FC<Props> = ({
  loading = false,
  accept,
  supportedFiles,
  uploadProps,
  handleUpload,
}) => {
  const antUploadProps: UploadProps = {
    ...uploadProps,
    beforeUpload: (file) => {
      handleUpload(file);
      return false;
    },
    name: uploadProps?.name || "file",
    showUploadList: uploadProps?.showUploadList || false,
    accept: accept || ".csv",
  };

  return (
    <Spin spinning={loading}>
      <Container>
        <div>
          <UploadOutlined
            width={24}
            height={24}
            style={{ fontSize: 32, color: "#36CFC9" }}
          />
        </div>
        <div style={{ marginTop: 16 }}>
          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            <span>Click </span>
            <StyledUpload {...antUploadProps}>
              <Typography.Link>here </Typography.Link>
            </StyledUpload>
            <span> to browse your file</span>
          </Typography.Title>
        </div>
        <div style={{ marginTop: 8 }}>
          <Typography.Text style={{ marginBottom: 0 }}>
            Files supported {supportedFiles && `(${supportedFiles.join(', ')})`}
          </Typography.Text>
        </div>
      </Container>
    </Spin>
  );
};

export default FileUpload;
