import { useState } from "react";
import { Link } from "react-router-dom";

import { Form, Input, Card, Row, Col, Alert } from "antd";
import { useMutation } from "react-query";

import { validateMessages } from "commons/utils/constants";
import useAuth from "./hooks/useAuth";
import Button from "commons/components/Button";

import "./styles/AuthForm.css";
import { LoginPayload, LoginResponse } from "./auth.types";

const LoginForm = () => {
  const auth = useAuth();
  const [isLoginFailed, setIsLoginFailed] = useState<boolean>(false);
  const authMutation = useMutation(
    async ({ email, password }: LoginPayload) =>
      await auth.signIn({ email, password }),
    {
      onSuccess: (val: LoginResponse) => { },
      onError: (val) => {
        setIsLoginFailed(true);
      },
    }
  );

  return (
    <Form
      onFinish={(val) => {
        authMutation.mutate(val);
      }}
      layout={"vertical"}
      requiredMark={false}
      validateMessages={validateMessages}
    >
      <Form.Item
        label={"Log in to Merchant Portal"}
        className={"form-title"}
      ></Form.Item>

      <Form.Item
        label={"Email"}
        name="email"
        rules={[{ required: true, message: "Please enter your email" }]}
      >
        <Input disabled={authMutation.isLoading} />
      </Form.Item>
      <Form.Item
        label={"Password"}
        name="password"
        rules={[{ required: true, message: "Please enter your password" }]}
      >
        <Input.Password size="large" disabled={authMutation.isLoading} />
      </Form.Item>
      {(isLoginFailed && !authMutation.isLoading) && (
        <Alert
          className={"form-error"}
          message="Invalid email or password"
          type="error"
          showIcon
        />
      )}
      <Form.Item>
        <Link className="form-text" to="/auth/reset-password">
          {"Forgot your password?"}
        </Link>
      </Form.Item>
      <Form.Item className={"form-footer"}>
        <Button
          type="primary"
          size={"large"}
          htmlType="submit"
          loading={authMutation.isLoading}
          block
        >
          {authMutation.isLoading ? "Logging in..." : "Login"}
        </Button>
        {/* {"No account?"}{" "}
        <Link to="/auth/signup">
          <a className={"form-text"}>{"Sign up now"}</a>
        </Link> */}
      </Form.Item>
    </Form>
  );
};

export const Login = () => {
  return (
    <Row align="middle" justify="center">
      <Col span={12} className={"auth-form-container"}>
        <Card>
          <LoginForm />
        </Card>
      </Col>
    </Row>
  );
};
