import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { Image, Layout } from "antd";
// import { isSessionCurrent, refreshUserSession} from '../auth/services/authContext'
import { Login } from "../auth/Login.page";
import janio_logo from "../assets/images/janio_logo.svg";
import footer from "../assets/images/footer.svg";
import SignUpPage from "./SignUp.page";
import useAuth from "../auth/hooks/useAuth";
import styled from "styled-components"

import "./styles/AuthRoute.scss";
import ResetPasswordPage from "./ResetPassword.page";
import CreatePasswordPage from "./CreatePassword.page";

const Header = styled(Layout.Header)`
  background: none;
`
const Footer = styled(Layout.Footer)`
  width: 100%;
  padding: 0;
`
const Content = styled(Layout.Content)`
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 48px;
`

export const AuthLayout = () => {
  const { auth } = useAuth();
  let { path } = useRouteMatch();

  if (auth.isAuthenticated) {
    return <Redirect to={{ pathname: "" }} />;
  }

  // if(userAuth.isSignedIn && !isSessionCurrent(userAuth)){
  //   refreshUserSession(auth);
  // }
  return (
    <Layout className={"auth-container"}>
      <Header>
        <a href="https://janio.asia/">
          <Image preview={false} src={janio_logo} />
        </a>
      </Header>
      <Content>
        <Switch>
          <Route path={`${path}/signin`}>
            <Login />
          </Route>
          <Route path={`${path}/signup`}>
            <SignUpPage />
          </Route>
          <Route path={`${path}/reset-password`}>
            <ResetPasswordPage />
          </Route>
          <Route path={`${path}/create-password/:resetToken`}>
            <CreatePasswordPage />
          </Route>
          {/*
          <Route path={`${path}/resend_verification`}>
            <Resend />
          </Route>
          <Route path={`${path}/activation/:uid/:token`}>
            <Activation />
          </Route> */}
        </Switch>
      </Content>
      <Footer>
        <Image preview={false} src={footer} />
      </Footer>
    </Layout>
  );
};
