import { Spin } from 'antd'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
`

export default function FullscreenSpinner() {
  return (
    <Container>
      <Spin spinning={true} />
    </Container>
  )
}
