export interface LoginPayload {
  email: string;
  password: string;
}
export interface LoginResponse {
  access: string;
  refresh: string;
}
export interface UserSignupPayload {
  username?: string;
  email?: string;
  password?: string;
  confirm_password?: string;
}
export interface UserSignupResponse extends LoginResponse {
  message?: string;
  username: string;
  email: string;
}

export interface Auth {
  token: string;
  refreshToken: string;
  isAuthenticated: boolean;
  userProfile?: UserProfile;
}
export const AuthDefaultValue: Auth = {
  token: "",
  refreshToken: "",
  isAuthenticated: false,
};

export interface UserProfile {
  first_name: string;
  email: string;
  brandId?: number;
}
export const UserProfileDefaultValue: UserProfile = {
  first_name: "",
  email: "",
};
