import { useState } from "react";
import { useRecoilState } from "recoil";

import authState from "../recoil";
import {
  fetchUserProfile,
  signIn as signInApi,
  signUp as signUpApi,
} from "../auth.api";
import {
  Auth,
  AuthDefaultValue,
  LoginPayload,
  LoginResponse,
  UserSignupPayload,
  UserSignupResponse,
} from "../auth.types";
import { AUTH_STORAGE_KEY } from "../constants";

const useAuth = () => {
  const [auth, setAuth] = useRecoilState<Auth>(authState);
  const [isLoading, setIsLoading] = useState(false);

  const handleLoginResponse = async (data: LoginResponse) => {
    const userProfile = await fetchUserProfile(data.access);
    const auth: Auth = {
      isAuthenticated: true,
      token: data.access,
      refreshToken: data.refresh,
      userProfile,
    };
    localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(auth));
    setAuth(auth);
  };

  const signIn = async (payload: LoginPayload) => {
    setIsLoading(true);
    const response: LoginResponse = await signInApi(payload);
    setIsLoading(false);
    handleLoginResponse(response);
    return response;
  };

  const signUp = async (payload: UserSignupPayload) => {
    setIsLoading(true);
    const response: UserSignupResponse = await signUpApi(payload);
    setIsLoading(false);
    handleLoginResponse(response);
    return response;
  };

  const signOut = () => {
    setAuth(AuthDefaultValue);
    localStorage.removeItem(AUTH_STORAGE_KEY);
  };

  return {
    auth,
    isLoading,
    signIn,
    signUp,
    signOut,
    handleLoginResponse,
  };
};

export default useAuth;
