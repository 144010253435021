import { Modal } from "antd";

const RouterPrompt = (message: string, closePrompt: (ok: boolean) => void) => {
  return (
    <Modal
      onOk={() => closePrompt(true)}
      onCancel={() => closePrompt(false)}
      visible={true}
    >
      {message || "Are you sure want to leave ?"}
    </Modal>
  )
}

export default RouterPrompt