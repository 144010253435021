import {
  LoginPayload,
  LoginResponse,
  UserProfile,
  UserSignupPayload,
  UserSignupResponse,
} from "./auth.types";
import axios from "axios";

export const AUTH_STORAGE_KEY = "AUTH_SESSION";

export const signIn = async (payload: LoginPayload) => {
  const url = "/api/auth/login/";
  const r = await axios.post<LoginResponse>(url, { ...payload });
  return r.data;
};
export const refreshToken = async (refreshToken: string) => {
  const url = "/api/auth/token/refresh/";
  const r = await axios.post<LoginResponse>(url, {
    refresh: refreshToken,
  });
  return r.data;
};

export const signUp = async (payload: UserSignupPayload) => {
  const url = "/api/auth/registration/";
  const r = await axios.post<UserSignupResponse>(url, { ...payload });
  return r.data;
};

export const fetchUserProfile = async (token: string) => {
  let response = await axios.get("/api/auth/user/me/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const setUserSession = (user: UserProfile) => {
  localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(user));
};

export const requestResetPassword = async (email: string) => {
  const url = "/api/auth/user_reset_password/";
  const r = await axios.post(url, { email });
  return r.data;
};

export const createNewPassword = async ({
  newPassword,
  resetToken,
}: {
  newPassword: string;
  resetToken: string;
}) => {
  const url = "/api/auth/user_reset_password/confirm/";
  const r = await axios.post(url, {
    token: resetToken,
    password: newPassword,
  });
  return r.data;
};
