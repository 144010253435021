import React from "react"
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";


import { AuthLayout } from "./auth/Auth.route";
// import { ProvideAuth } from "../auth/services/authContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import { ThemeProvider } from "styled-components";

// import RecoilDebugger from "commons/components/RecoilDebugger"

import { theme } from "./commons/utils/theme";
import "./commons/styles/App.scss";
import RecoilNexus from "recoil-nexus";
import "./App.less"
import RouterPrompt from "commons/components/RouterPrompt";
import FullscreenSpinner from "commons/components/FullscreenSpinner";

const PrivateLayout = React.lazy(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "priv" */
  "./commons/PrivateRoute"
))

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function getUserConfirmation(message: string, callback: (ok: boolean) => void) {
  const container = document.createElement("div");
  container.setAttribute("custom-confirmation-navigation", "");
  document.body.appendChild(container);

  const closePrompt = (callbackState: boolean) => {
    callback(callbackState)
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(container)
      document.body.removeChild(container)
    }, 1000)
  }
  const Component = () => RouterPrompt(message, closePrompt)
  ReactDOM.render(<Component />, container)
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <RecoilNexus />
        {/* <RecoilDebugger /> */}
        <ThemeProvider theme={theme}>
          <React.Suspense fallback={<FullscreenSpinner />}>
            <Router
              getUserConfirmation={getUserConfirmation}
            >
              <Switch>
                <Route path="/auth">
                  <AuthLayout />
                </Route>
                <Route path="">
                  <PrivateLayout />
                </Route>
              </Switch>
            </Router>
          </React.Suspense>
        </ThemeProvider>
      </RecoilRoot>
    </QueryClientProvider>
  );
}

export default App;
