import { Tabs as AntdTabs } from "antd"
import styled from "styled-components"

const Tabs = styled(AntdTabs)`
  .ant-tabs-tab:hover {
      color: ${props => props.theme.color.cyanLight};
  }
  & .ant-tabs-tab-active > .ant-tabs-tab-btn {
    color: ${props => props.theme.color.cyanLight}
  }
  & .ant-tabs-ink-bar {
    background: ${props => props.theme.color.cyanLight};
  }
`

export default Tabs