import { Form, Input, Row, Col, Card } from "antd";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import Button from "commons/components/Button";
import * as yup from "yup";
import { useMutation } from "react-query";
import useAuth from "./hooks/useAuth";
import { UserSignupPayload, UserSignupResponse } from "./auth.types";

// interface SignupFormValues {
//   username?: string;
//   email?: string;
//   password?: string;
//   confirmPassword?: string;
// }

const SignUpPage = () => {
  return (
    <Row align="middle" justify="center">
      <Col span={12} className={"auth-form-container"}>
        <Card>
          <SignupForm />
        </Card>
      </Col>
    </Row>
  );
};

const SignupForm: React.FC = () => {
  const f = useFormik<UserSignupPayload>({
    initialValues: {
      username: undefined,
      email: undefined,
      password: undefined,
      confirm_password: undefined,
    },
    validationSchema: yup.lazy((props) => {
      return yup.object().shape({
        username: yup.string().required("Required"),
        email: yup.string().required("Required").email("Enter a valid email"),
        password: yup.string().required("Required"),
        confirm_password: yup
          .string()
          .required("Required")
          .test("password-match", "Password must match", function (value) {
            return value === this.parent.password;
          }),
      });
    }),
    onSubmit: async (values, actions) => {
      authMutation.mutate(values);
    },
  });

  const auth = useAuth();
  const authMutation = useMutation(
    async (values: UserSignupPayload) => await auth.signUp(values),
    {
      onSuccess: (val: UserSignupResponse) => { },
      onError: (val) => {
        console.log("errors", val);
        let error: any = val;
        f.setErrors({ ...error.response.data });
      },
    }
  );

  return (
    <Form
      layout={"vertical"}
      requiredMark={false}
      onSubmitCapture={f.handleSubmit}
    >
      <Form.Item
        label={"Signup to Merchant Portal"}
        className={"form-title"}
      ></Form.Item>

      <Form.Item
        label="Username"
        hasFeedback={!!(f.touched?.username && f.errors?.username)}
        validateStatus={
          !!(f.touched?.username && f.errors?.username) ? "error" : ""
        }
        help={f.touched?.username && f.errors?.username}
      >
        <Input
          name="username"
          value={f.values.username}
          onChange={f.handleChange}
          onBlur={f.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label="Email"
        hasFeedback={!!(f.touched?.email && f.errors?.email)}
        validateStatus={!!(f.touched?.email && f.errors?.email) ? "error" : ""}
        help={f.touched?.email && f.errors?.email}
      >
        <Input
          name="email"
          value={f.values.email}
          onChange={f.handleChange}
          onBlur={f.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label="Password"
        hasFeedback={!!(f.touched?.password && f.errors?.password)}
        validateStatus={
          !!(f.touched?.password && f.errors?.password) ? "error" : ""
        }
        help={f.touched?.password && f.errors?.password}
      >
        <Input.Password
          name="password"
          value={f.values.password}
          onChange={f.handleChange}
          onBlur={f.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label="Confirm Password"
        help={f.touched?.confirm_password && f.errors?.confirm_password}
        hasFeedback={
          !!(f.touched?.confirm_password && f.errors?.confirm_password)
        }
        validateStatus={
          !!(f.touched?.confirm_password && f.errors?.confirm_password)
            ? "error"
            : ""
        }
      >
        <Input.Password
          name="confirm_password"
          value={f.values.confirm_password}
          onChange={f.handleChange}
          onBlur={f.handleBlur}
        />
      </Form.Item>

      <Form.Item className={"form-footer"} style={{ marginTop: 16 }}>
        <Button
          type="primary"
          size={"large"}
          htmlType="submit"
          className={"form-button"}
          loading={authMutation.isLoading}
        >
          {authMutation.isLoading ? "Submitting..." : "Signup"}
        </Button>
        {"Have account?"}{" "}
        <Link to="/auth/signin">
          <span className={"form-text"}>{"Sign in now"}</span>
        </Link>
      </Form.Item>
    </Form>
  );
};

export default SignUpPage;
