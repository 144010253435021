import styled from "styled-components";

const DivFlex = styled.div<{
  justify?: string;
  align?: string;
}>`
  display: flex;
  justify-content: ${(props) => props.justify || "space-between"};
  align-items: ${(props) => props.align || "center"};
`;

export default DivFlex;
