import { useState } from "react"
import { Col, Row, Card, Form, Typography, Input } from "antd";
import { Alert, Button } from "commons/components";
import { useMutation } from "react-query";
import { createNewPassword } from "./auth.api";
import { Link, useParams } from "react-router-dom"
import { CheckCircleFilled } from "@ant-design/icons";

const CreatePasswordPage = () => {
  const { resetToken } = useParams<{ resetToken: string }>()

  const [submitStatus, setSubmitStatus] = useState<"success" | "error" | undefined>()
  const [error, setError] = useState()

  const mutation = useMutation(
    async ({ newPassword }: { newPassword: string }) =>
      await createNewPassword({ resetToken, newPassword }),
    {
      onSuccess: response => setSubmitStatus("success"),
      onError: (error: any) => {
        const responseData = error?.response?.data?.password
        setSubmitStatus("error")
        setError(responseData)
      }
    }
  )

  if (submitStatus === "success") {
    return (
      <Row align="middle" justify="center">
        <Col span={12} className={"auth-form-container"}>
          <Card>
            <Row gutter={[24, 24]}>
              <Col span={24} style={{ textAlign: "center" }}>
                <CheckCircleFilled style={{ fontSize: 80, color: "#21ce98" }} />
                <Typography.Title style={{ marginTop: 24, marginBottom: 0 }} level={5}>
                  Your password successfully updated. <Link to="/auth/signin">
                    <a className={"form-text"}>{"Login here"}</a>
                  </Link>
                </Typography.Title>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    )
  }

  return (
    <Row align="middle" justify="center">
      <Col span={12} className={"auth-form-container"}>
        <Card>
          <Form
            layout={"vertical"}
            requiredMark={false}
            onFinish={(val) => {
              mutation.mutate(val);
            }}
          >
            <div>
              <Typography.Title level={5}>Create new password</Typography.Title>
            </div>

            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input.Password autoFocus disabled={mutation.isLoading} />
            </Form.Item>

            <Form.Item className={"form-footer"} style={{ marginTop: 16 }}>
              {(submitStatus === "error" && !mutation.isLoading) &&
                <div style={{ marginBottom: 16 }}>
                  {error ?
                    <Alert type="error" message={<ErrorMessages error={error} />} />
                    : <Alert type="error" message="Unable to create new password" />
                  }
                </div>
              }

              <Button
                block
                type="primary"
                size={"large"}
                htmlType="submit"
                className={"form-button"}
                loading={mutation.isLoading}
              >
                {mutation.isLoading ? "Submitting..." : "Update my password"}
              </Button>
              {"Remeber your password?"}{" "}

              <Link to="/auth/signin">
                <a className={"form-text"}>{"Login here"}</a>
              </Link>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}

const ErrorMessages: React.FC<{ error: string[] }> = ({ error }) => {
  return (
    <ul style={{ textAlign: "left", marginBottom: 0 }}>
      {error.map(e => <li>{e}</li>)}
    </ul>
  )
}

export default CreatePasswordPage
