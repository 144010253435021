import { Alert as AntdAlert } from "antd";
import styled from "styled-components";

const Alert = styled(AntdAlert)`
  border-radius: 5px;
  &.ant-alert-error {
    background-color: ${(props) => props.theme.color.dangerLighter};
    border: 1px solid ${(props) => props.theme.color.danger};
  }
`;

export default Alert;
