import { atom } from "recoil";
import { Auth, UserProfile, UserProfileDefaultValue } from "../auth.types";
import { AUTH_STORAGE_KEY } from "../constants";

export const authState = atom<Auth>({
  key: "authState",
  default: JSON.parse(localStorage.getItem(AUTH_STORAGE_KEY) || "{}"),
  // default: {
  //   token: "string",
  //   refreshToken: "string",
  //   isAuthenticated: true
  // }
});

export const userProfileAtom = atom<UserProfile>({
  key: "auth-user-profile",
  default: UserProfileDefaultValue,
});
