import { useState } from "react";
import { Col, Row, Card, Form, Input, Typography } from "antd";
import { Alert, Button } from "commons/components";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import { requestResetPassword } from "./auth.api";
import { CheckCircleFilled } from "@ant-design/icons";

const ResetPasswordPage = () => {
  return (
    <Row align="middle" justify="center">
      <Col span={12} className={"auth-form-container"}>
        <Card>
          <ResetPasswordForm />
        </Card>
      </Col>
    </Row>
  );
}

const ResetPasswordForm: React.FC = () => {
  const [submitStatus, setSubmitStatus] = useState<"success" | "error" | undefined>()

  const mutation = useMutation(
    async ({ email }: { email: string }) => {
      setSubmitStatus(undefined)
      await requestResetPassword(email)
    },
    {
      onSuccess: (response) => setSubmitStatus("success"),
      onError: (val) => setSubmitStatus("error")
      //   let error: any = val;
      //   setIsLoginFailed(true);

      //   setError({ ...error.response.data });
      // },
    }
  );

  if (submitStatus === "success") {
    return (
      <Row gutter={[24, 24]}>
        <Col span={24} style={{ textAlign: "center" }}>
          <CheckCircleFilled style={{ fontSize: 80, color: "#21ce98" }} />
          <Typography.Title style={{ marginTop: 24, marginBottom: 0 }} level={5}>
            Reset password instructions sent to your email.
          </Typography.Title>
        </Col>
      </Row>
    )
  }

  return (
    <Form
      layout={"vertical"}
      requiredMark={false}
      onFinish={(val) => {
        mutation.mutate(val);
      }}
    >
      <div>
        <Typography.Title level={5}>Reset Password</Typography.Title>
        <Typography.Paragraph>Enter your email, and we’ll send you instructions on how to reset your password.</Typography.Paragraph>
      </div>
      <Form.Item
        label="Email Address"
        name="email"
        rules={[
          { required: true, message: "Required" },
          { type: "email", message: "Please enter a valid email" }
        ]}

      >
        <Input autoFocus disabled={mutation.isLoading} />
      </Form.Item>

      {(submitStatus === "error" && !mutation.isLoading) &&
        <div>
          <Alert type="error" message="Unable to reset your password" />
        </div>
      }

      <Form.Item className={"form-footer"} style={{ marginTop: 16 }}>
        <Button
          block
          type="primary"
          size={"large"}
          htmlType="submit"
          className={"form-button"}
          loading={mutation.isLoading}
        >
          {mutation.isLoading ? "Submitting..." : "Send me reset instructions"}
        </Button>

        {"Remeber your password?"}{" "}
        <Link to="/auth/signin">
          <a className={"form-text"}>{"Login here"}</a>
        </Link>
      </Form.Item>
    </Form>
  )
}

export default ResetPasswordPage
