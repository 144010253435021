import { Card as AntdCard, CardProps as AntdCardProps } from "antd";
import React from "react";
import styled from "styled-components";

interface CardProps extends AntdCardProps {
  isActive?: boolean;
}

const CustomCard: React.FC<CardProps> = ({ isActive = false, ...rest }) => (
  <AntdCard {...rest} />
);

const Card = styled(CustomCard)`
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    !!props.isActive ? props.theme.color.primary : props.theme.color.greyLight2};

  ${(props) =>
    !!props.isActive &&
    `
      color: ${props.theme.color.primary};
      svg path {
        fill: ${props.theme.color.primary};
      }
    `}
`;

export default Card;
