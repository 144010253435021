export const theme = {
  color: {
    text: "rgba(0, 0, 0, 0.85)",
    primary: "#050593",
    secondary: "#003172",
    secondaryLight: '#1FCECA1A',
    success: "#21ce98",
    greyOverlay: "rgba(0, 0, 0, 0.15);",
    dark: "#262626",
    grey: "#595959",
    greyLight: "#bfbfbf",
    greyLight2: "#D9D9D9",
    greyLight3: "#8C8C8C",
    greyLightest: "#F0F0F0",
    white: "#fff",
    cyan: "#36CFC9",
    cyanLight: "#13C2C2",
    danger: "#FFA39E",
    dangerLighter: "#FFF1F0",
  },
};
