import { Button as AntdButton } from "antd";
import styled from "styled-components";

const Button = styled(AntdButton)`
  border-radius: 2px;

  ${(props) =>
    props.type === "primary" &&
    `
  background-color: ${props.theme.color.primary};
  border-color: ${props.theme.color.primary};

  &:focus {
    background-color: ${props.theme.color.primary};
    border-color: ${props.theme.color.primary};
  }
  `}

  padding-left: 16px;
  padding-right: 16px;
  &:not([disabled]):hover {
    background-color: lighten(${(props) => props.theme.color.primary}, 10%);
  }
`;

export default Button;
